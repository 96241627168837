export enum AccessPointTypes {
  ACCESS_POINT_FETCH_LIST = "ACCESS_POINT/FETCH_LIST",
  ACCESS_POINT_LOADING = "ACCESS_POINT/LOADING"
}

export interface IHours {
  mon_open: string;
  mon_close: string;
  tue_open: string;
  tue_close: string;
  wed_open: string;
  wed_close: string;
  thu_open: string;
  thu_close: string;
  fri_open: string;
  fri_close: string;
  sat_open: string;
  sat_close: string;
  sun_open: string;
  sun_close: string;
}

export interface IAddress {
  freeform_address: string;
  street_number?: string;
  street_name?: string;
  municipality?: string;
  state?: string;
  state_name?: string;
  postal_code?: string;
  county?: string;
  country?: string;
}

/* export interface ICoordinateReferenceSystem {
  type: string;
  properties: object;
} */

export interface ICoordinate {
  lon: number;
  lat: number;
}

export interface ILocation {
  type: string;
  coordinates: ICoordinate;
  //crs: ICoordinateReferenceSystem;
}

export interface IProperty {
  network_name: string;
  instructions: string;
}

export interface IAccessPoint {
  id: string;
  site_name: string;
  type: string;
  website: string;
  display_name: string;
  organization_name: string;
  distance_miles: number;
  location: ILocation;
  address: IAddress;
  business_hours: IHours;
  properties: IProperty;
}

export interface IAccessPointState {
  readonly access: IAccessPoint[];
  readonly loading: boolean;
}


export interface IAccessPointLoadingAction {
  type: AccessPointTypes.ACCESS_POINT_LOADING;
}
export interface IAccessPointListAction {
  type: AccessPointTypes.ACCESS_POINT_FETCH_LIST;
  access: IAccessPoint[];
}

export type AccessPointActions = IAccessPointListAction | IAccessPointLoadingAction;