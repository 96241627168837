import React from "react";
import { IAccessPoint } from "../store/types/AccessPointTypes";
import {isIOS, isMobile} from "react-device-detect";
import { ValidateTime } from "../common/utils/TimeUtils";

interface MapTrayLocationProp {
    location: IAccessPoint,
    showMicroCard: boolean,
    closeCard: () => void,
    openLocationDetails: (location: IAccessPoint) => any
  }

export function getDirectionsLink(props: IAccessPoint): string {
    return (isIOS && isMobile ? 
        `maps://maps.google.com/maps?daddr=${props.location.coordinates.lat},${props.location.coordinates.lon}&amp;ll=` : 
        `https://maps.google.com/maps?daddr=${props.location.coordinates.lat},${props.location.coordinates.lon}&amp;ll=`)
}

const MiniDetailsCard = (prop: MapTrayLocationProp) => {
    if(prop.location == null || prop.location.id == null)
    {
        return (<div className={"location-card-micro-details"}>
        </div>);
    }
    let open = "";
    let close = "";
    let timeString;
    const dayOfWeek = new Date().getDay();

    switch(dayOfWeek) { 
        case 0: { 
            open = prop.location.business_hours.sun_open;
            close = prop.location.business_hours.sun_close;
            break; 
        } 
        case 1: { 
            open = prop.location.business_hours.mon_open;
            close = prop.location.business_hours.mon_close;
            break; 
        } 
        case 2: { 
            open = prop.location.business_hours.tue_open;
            close = prop.location.business_hours.tue_close;
            break; 
        }
        case 3: { 
            open = prop.location.business_hours.wed_open;
            close = prop.location.business_hours.wed_close;
            break; 
        } 
        case 4: { 
            open = prop.location.business_hours.thu_open;
            close = prop.location.business_hours.thu_close;
            break; 
        }
        case 5: { 
            open = prop.location.business_hours.fri_open;
            close = prop.location.business_hours.fri_close;
            break; 
        } 
        case 6: { 
            open = prop.location.business_hours.sat_open;
            close = prop.location.business_hours.sat_close;
            break; 
        } 
        default: { 
           //statements; 
           break; 
        } 
     }

     if(open && close)
     {
         timeString = ValidateTime(open,close);
     }


  return (
    <div className={prop.showMicroCard ? "location-card-micro-details open" : "location-card-micro-details"}>
        <div className="location-card-micro-details-inner">
            <span className="close acp acp-close" onClick={prop.closeCard}></span>
            <h2>{prop.location.display_name}</h2>            
            <section className="status micro-details-grid">
                <div className="left" style={{textAlign: 'center'}}>
                <span className="hours">{timeString}</span>
                </div>
                <div className="middle hide">
                    <span className="acp acp-dot-single"></span>
                </div>
                <div className="right hide">
                    <span className="wifi on">Wi-Fi is Currently On</span>
                </div>
                </section>

                <section className="button-group micro-details-grid">
                <div className="left">
                    <a className="button accent button-icon" onClick={() => {prop.openLocationDetails(prop.location)}}>
                    View Details
                    <span className="icon acp acp-expand"></span>
                    </a>
                </div>
                <div className="middle"> </div>
                <div className="right">
                    <a href={getDirectionsLink(prop.location)} className="button button-icon on">
                    Open in Maps
                    <span className="icon acp acp-directions"></span>
                    </a>
                </div>
            </section>
        </div>
    </div>
  );
};

export default MiniDetailsCard;