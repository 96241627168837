const CLOSED = 'Closed'
const HOURS_24 = '24 Hours'
const TIME_ZERO = '00:00'
const TIME_24OCLOCK = '24:00'

const SEARCHBOX = {
    INITIAL_MESSAGE: 'Enter Address',
    NO_MATCH_FOUND: 'No Matching Address Found'
}

const SEPARATOR = {
    COLON: ':'
}

const MERIDIEM = {
    ANTE: 'AM',
    POST: 'PM'
}

const FAVORITES = 'favorites'

export {CLOSED, TIME_ZERO, TIME_24OCLOCK, HOURS_24, SEARCHBOX, SEPARATOR, MERIDIEM, FAVORITES};