import React, { useState } from 'react';
import '../sass/main.scss';
import Autocomplete, { /*createFilterOptions*/ } from '@material-ui/lab/Autocomplete';
import axios, {AxiosResponse} from 'axios';
import { IAddressResponseType } from '../store/types/AddressResponseTypes';
import { Popper } from '@material-ui/core';
import { SEARCHBOX } from '../common/constants/constants';


interface MapSearchProp{
    show: boolean,
    close: () => void,
    flyTo: (location: IAddressResponseType) => void,
    setCurrentLocation: () => void
}

const MapSearch = (prop: MapSearchProp) => {
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(new Array<IAddressResponseType>());
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  const loading = open && options.length === 0 && inputValue.length > 3;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      setIsRequestCompleted(false)
      const results = await axios.get(
        `${process.env.REACT_APP_SERVICES_API_HOST}/api/address?q=${inputValue}&predict=true&code=${process.env.REACT_APP_SERVICES_API_KEY}`,
      ).then((response: AxiosResponse) => {
        const access: IAddressResponseType[] = response.data;
        if(response.data && !response.data.length){
          setIsRequestCompleted(true)
        }
        return access
      })
      if (active) {
        setOptions(results);
      }
    })();

    return () => {
      active = false;
    };
  }, [inputValue, loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  
  // When a new address is selected, use flyTo on the map component.
  function onSelected(selectedValue: any){
    const loc = selectedValue as IAddressResponseType
    if(loc != null)
    {
        prop.close();
        prop.flyTo(loc);
    }
  }

  function inputChange(val: string){
    setOptions([])
    setInputValue(val)
  }

  const CustomPopper = function (props: any) {
    return (<Popper {...props} className="search-autocomplete-menu" placement='bottom-start' modifiers={{flip: {enabled: false}}} />)
  }


  return (
    <div className={prop.show ? "map-search-card open" : "map-search-card close"}>
      <span className="close acp acp-close" onClick={prop.close}></span>       
      <header>
        <h2>Search</h2>
      </header>     
      <section className="search-container">
        <div className="search-inputs">
          <i className="button-submit acp acp-arrow-right"></i>
          <Autocomplete
            PopperComponent={CustomPopper}
            ListboxProps={{ style: { maxHeight: '23vh' } }}            
            id="filter-demo"
            noOptionsText={isRequestCompleted ? SEARCHBOX.NO_MATCH_FOUND : SEARCHBOX.INITIAL_MESSAGE}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            options={options}
            loading={!isRequestCompleted && loading}
            getOptionLabel={(loc: IAddressResponseType) => loc.address.freeform_address}
            onInputChange={(event, newInputValue) => inputChange(newInputValue)}
            onChange = {(event, selectedValue) => onSelected(selectedValue)}
            filterOptions = {(options, state) => options}
            renderInput={(params) => 
                <div ref={params.InputProps.ref} className="text-input-container">
                    <input type="text" className="text-field" placeholder="Enter City, State, or ZIP Code" {...params.inputProps} />
                </div>
            }
            />         
        </div>
      </section>
      
      <section className="get-position-container">
        <span className="trigger-get-position" onClick={prop.setCurrentLocation}>
          <i className="acp acp-target"></i>
          Use My Current Location
        </span>
      </section> 
    </div>
  )
};

export default MapSearch;