import React from "react";

interface WirelessIconProp {
    type: string
}

const WirelessIcon = (prop: WirelessIconProp) => {
  let iconContainerClass = "icon-container ";
  let iconClass;

  switch(prop.type) { 
    case "on": { 
       iconContainerClass += "on";
       iconClass = "acp acp-wifi-on-text"
       break; 
    } 
    case "off": { 
        iconContainerClass += "off";
        iconClass = "acp acp-wifi-off-text"
       break; 
    }
    case "unknown": { 
        iconContainerClass += "unknown";
        iconClass = "acp acp-wifi"
        break; 
     } 
    default: { 
       //statements; 
       break; 
    } 
 } 
  return (
      <div className={iconContainerClass}>
          <i className={iconClass}></i>        
      </div>
  );
};

export default WirelessIcon;