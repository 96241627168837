import React from 'react';
import '../sass/main.scss';
import { IAccessPoint } from '../store/types/AccessPointTypes';
import WirelessIcon from './WirelessIcon';
import { ValidateTime } from "../common/utils/TimeUtils";

interface MapTrayLocationProp {
    location: IAccessPoint,
    setLocation: (location: any) => void
  }

const MapTrayLocation = (prop: MapTrayLocationProp) => {
    let open = "";
    let close = "";
    let timeString;
    const dayOfWeek = new Date().getDay();

    switch(dayOfWeek) { 
        case 0: { 
            open = prop.location.business_hours.sun_open;
            close = prop.location.business_hours.sun_close;
            break; 
        } 
        case 1: { 
            open = prop.location.business_hours.mon_open;
            close = prop.location.business_hours.mon_close;
            break; 
        } 
        case 2: { 
            open = prop.location.business_hours.tue_open;
            close = prop.location.business_hours.tue_close;
            break; 
        }
        case 3: { 
            open = prop.location.business_hours.wed_open;
            close = prop.location.business_hours.wed_close;
            break; 
        } 
        case 4: { 
            open = prop.location.business_hours.thu_open;
            close = prop.location.business_hours.thu_close;
            break; 
        }
        case 5: { 
            open = prop.location.business_hours.fri_open;
            close = prop.location.business_hours.fri_close;
            break; 
        } 
        case 6: { 
            open = prop.location.business_hours.sat_open;
            close = prop.location.business_hours.sat_close;
            break; 
        } 
        default: { 
           //statements; 
           break; 
        } 
     }

     if(open && close)
     {
         timeString = ValidateTime(open,close);
     }

  return (
    <div className={"location-menu-item-row location-row"} onClick={() => {prop.setLocation(prop.location)}}>        
        <div className="item-column icon">                    
            <WirelessIcon type={"unknown"}></WirelessIcon>
        </div>
        <div className="item-column title">
            <h3>{prop.location.display_name}</h3>
            <p>{timeString}</p>
        </div>
        <div className="item-column action">
            <span className="acp acp-expand"></span>
        </div>
    </div>
  )
};

export default MapTrayLocation;