import React from 'react';
import Header from './components/Header'
import Footer from './components/Footer'
import './sass/main.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import MapboxReactCluster from './components/MapboxReactCluster';
import {isIOS, isAndroid, isChrome, isIE, isEdge, isEdgeChromium, isSafari, isMobileSafari, isWindows, isMacOs, isFirefox} from 'react-device-detect'

function App() {

  function getDeviceClasses() {
    let classes = "body-wrap"

    isIOS && (classes += " device-ios")
    isAndroid && (classes += " device-android")
    isWindows && (classes += " device-windows")
    isMacOs && (classes += " device-mac")
    isChrome && (classes += " browser-chrome")
    isMobileSafari && (classes += " browser-mobile-safari")
    isSafari && !isMobileSafari && (classes += " browser-safari")
    isEdgeChromium && (classes += " browser-edge-chromium")
    isEdge && !isEdgeChromium && (classes += " browser-edge")
    isIE && (classes += " browser-ie")
    isFirefox && (classes += " browser-firefox")

    return classes;
  }

  // TODO: Add page name to the body class (74521).
  return (
    <Router>
      
      <div className={getDeviceClasses()} style={{height: "100%", width: "100%"}}>
        <Header />
        <Switch>
          <Route path="/map">
            <MapboxReactCluster />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
            <Footer />
          </Route>
          <Route path="/about-us">
            <AboutUs />
            <Footer />
          </Route>          
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        </div>
    </Router>
  )
}
export default App;
