import React from 'react';
import {ReactComponent as ACPLogoShort} from '../assets/logos/logo-lockup-short.svg';
import {ReactComponent as ACPLogoFull} from '../assets/logos/logo-lockup-full.svg';
import '../sass/main.scss';
import NavigationDrawer from './NavigationDrawer'

const Header = () => {
  return (
    <header className="header-wrap">
      <div className="header">   
        <div className="logo-wrap">             
          <a href="/">                
            <ACPLogoShort className="logo short" />
            <ACPLogoFull className="logo full" /> 
          </a>
        </div>      
        <NavigationDrawer />
      </div>      
    </header>
  )
};

export default Header;