import React from "react";
import { IAccessPoint } from "../store/types/AccessPointTypes";

interface LocationHoursCardProp {
    showHours: boolean,
    closeCard: () => void,
    location: IAccessPoint
  }

const LocationDetailsHoursCard = (prop: LocationHoursCardProp) => {
    const formatTime = (oldFormatTime: string) => {
        var oldFormatTimeArray = oldFormatTime.split(":");
    
        var HH = parseInt(oldFormatTimeArray[0]);
        var min = oldFormatTimeArray[1];
    
        var AMPM = HH >= 12 && HH !== 24 ? "PM" : "AM";
        var hours;
        if(HH === 0){
          hours = HH + 12;
        } else if (HH > 12) {
          hours = HH - 12;
        } else {
          hours = HH;
        }
        var newFormatTime = hours + ":" + min + " " + AMPM;
        return newFormatTime;
    }

    const ValidateTime = (openTime: string, closeTime: string) => {
        if ('Closed' === openTime)
            return 'Closed'
        else if ('00:00' === openTime && '24:00' === closeTime)
            return '24 Hours'
        else
            return `${formatTime(openTime)} - ${formatTime(closeTime)}`
    }

  return (
    <div className={prop.showHours ? "location-details-content-card location-details-card open" : "location-details-content-card location-details-card"}>
        <span className="close acp acp-compress" onClick={prop.closeCard}></span>
        <header className="">
            <h1>Wi-Fi Hours &amp; <br/> Availability</h1>            
        </header>

        <div className="overflow-y-scroll">
            <section className="wifi-status-widget unknown">
                {prop.location.properties.network_name && <div className="wifi-details">
                    <div className="wifi-ssid">
                        <span className="label">Network Name</span>
                        <span className="description">{prop.location.properties.network_name}</span>
                    </div>
                    <div className="wifi-connection">
                        <span className="label">Connection Info</span>
                        <span className="description">
                            See Notes & Instructions
                        </span>
                    </div>
                </div>}
            </section>

            <section className="location-hours-list">
                
                <div className="location-hours-row">
                    <span className="label">Sunday</span>
                    <span className="description">{ValidateTime(prop.location.business_hours.sun_open, prop.location.business_hours.sun_close)}</span>
                </div>

                <div className="location-hours-row">
                    <span className="label">Monday</span>
                    <span className="description">{ValidateTime(prop.location.business_hours.mon_open, prop.location.business_hours.mon_close)}</span>
                </div>

                <div className="location-hours-row">
                    <span className="label">Tuesday</span>
                    <span className="description">{ValidateTime(prop.location.business_hours.tue_open, prop.location.business_hours.tue_close)}</span>
                </div>

                <div className="location-hours-row">
                    <span className="label">Wednesday</span>
                    <span className="description">{ValidateTime(prop.location.business_hours.wed_open, prop.location.business_hours.wed_close)}</span>
                </div>

                <div className="location-hours-row">
                    <span className="label">Thursday</span>
                    <span className="description">{ValidateTime(prop.location.business_hours.thu_open, prop.location.business_hours.thu_close)}</span>
                </div>

                <div className="location-hours-row">
                    <span className="label">Friday</span>
                    <span className="description">{ValidateTime(prop.location.business_hours.fri_open, prop.location.business_hours.fri_close)}</span>
                </div>

                <div className="location-hours-row">
                    <span className="label">Saturday</span>
                    <span className="description">{ValidateTime(prop.location.business_hours.sat_open, prop.location.business_hours.sat_close)}</span>
                </div>            
            </section>
        </div>         
    </div>
  );
};

export default LocationDetailsHoursCard;