import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import ReactMapboxGl, { ZoomControl, GeoJSONLayer, Layer } from 'react-mapbox-gl';
import axios, { AxiosResponse } from 'axios';
import { useState } from 'react'
import { IAccessPoint } from '../store/types/AccessPointTypes'
import MapTray from './MapTray';
import MiniDetailsCard from './MiniDetailsCard';
import { IAddressResponseType } from '../store/types/AddressResponseTypes';
import LocationDetailsCard from './LocationDetailsCard';
import GeoJSON from 'geojson';

const Map = ReactMapboxGl({
  accessToken: String(process.env.REACT_APP_MB_API_KEY),
  logoPosition: "top-right"
});

const MapboxReactCluster = () => {
  const [locations, setLocations] = useState({} as GeoJSON.FeatureCollection);
  const [isTrayOpen, setIsTrayOpen] = useState(false);
  const [showMicroCard, setshowMicroCard] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState({} as IAccessPoint);
  const [showLocationDetailsCard, setshowLocationDetailsCard] = useState(false);
  const [mapValues, setMapValues] = useState({
    lon: -92.6238,
    lat: 44.8614,
    zoom: 10
  })
  const [favoriteIDs, setFavoriteIDs] = useState([] as any)
  const [locationsForFavorites, setLocationsForFavorites] = useState([] as GeoJSON.Feature[]);
  const [fetchedAllLocations, setFetchedAllLocations] = useState(false)
  const mapRef = useRef(null);


  // Initialize map when component mounts

  const fetchLocations = () => {
    if (!fetchedAllLocations && mapValues.zoom <= 8) {
      fetchAllLocations()
    }
    else if (!fetchedAllLocations) {
      const distance = Math.ceil(2 ** (20 - mapValues.zoom) / 10);
      axios.get(
        `${process.env.REACT_APP_SERVICES_API_HOST}/api/services?service_type=Public%20WiFi&lat=${mapValues.lat}&lon=${mapValues.lon}&radius_mi=${distance}&code=${process.env.REACT_APP_SERVICES_API_KEY}`,
        { headers: { 'accept': 'application/geo+json' } }
      ).then((response: AxiosResponse) => {
        const access: GeoJSON.FeatureCollection = response.data;
        setLocations(access);
        setLocationsForFavorites(access.features)
      })
    }
  }

  const convertToAccessPoint = (feature: GeoJSON.Feature): IAccessPoint => {
    let loc = feature.properties as IAccessPoint;
    return loc;
  }
  const fetchAllLocations = () => {
    axios.get(
      `${process.env.REACT_APP_SERVICES_API_HOST}/api/services?service_type=Public%20WiFi&lat=${mapValues.lat}&lon=${mapValues.lon}&radius_mi=10000&code=${process.env.REACT_APP_SERVICES_API_KEY}`,
      { headers: { 'accept': 'application/geo+json' } }
    ).then((response: AxiosResponse) => {
      const access: GeoJSON.FeatureCollection = response.data;
      setLocations(access);
      setLocationsForFavorites(access.features)
      setFetchedAllLocations(true)
    })
  }

  useEffect(() => {
    fetchLocations()
    const favorite_ids = localStorage.getItem("favorites");
    if(favorite_ids !== null)
    {
        setFavoriteIDs(JSON.parse(favorite_ids));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const toggleTray = () => {
    setIsTrayOpen(!isTrayOpen)
  }

  const locationClickTrigger = (location: IAccessPoint) => {
    setSelectedLocation(location);
    setshowLocationDetailsCard(true)
  }

  const closeCard = () => {
    setshowMicroCard(false);
  }

  const closeLocationDetailsCard = () => {
    setshowLocationDetailsCard(false);
  }

  const mapLocationClick = (location: GeoJSON.Feature) => {
    setSelectedLocation(convertToAccessPoint(location));
    setshowMicroCard(true);
    setshowLocationDetailsCard(false);
  }

  const microDetailsClick = (location: IAccessPoint) => {
    setSelectedLocation(location);
    setshowMicroCard(false);
    setshowLocationDetailsCard(true);
  }

  const mapOnMove = (map: mapboxgl.Map, evt: React.SyntheticEvent<any>) => {
    setMapValues({
      lat: map.getCenter().lat,
      lon: map.getCenter().lng,
      zoom: map.getZoom()
    });
  }

  const mapOnMoveEnd = (map: mapboxgl.Map, evt: React.SyntheticEvent<any>) => {
    fetchLocations();
  }

  const setCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const mapRefNode = (mapRef as any).current.state.map
      mapRefNode.flyTo({
        center: {
          lat: position.coords.latitude,
          lon: position.coords.longitude
        },
        essential: true
      });
    });
  }

  const addToFavorites = (location: IAccessPoint) => {
    let temp = favoriteIDs;
    temp.push(location.id);
    localStorage.setItem("favorites", JSON.stringify(temp));
    setFavoriteIDs(temp);
  }

  const removeFromFavorites = (location: IAccessPoint) => {
    let temp = favoriteIDs;
    temp = temp.filter((item: any) => item !== (location.id))
    localStorage.setItem("favorites", JSON.stringify(temp));
    setFavoriteIDs(temp);
  }

  const unclusteredPointClick = (evt: any) => {
    if(evt.features[0].properties.cluster !== false)
    {
        const loc_tmp = evt.features[0].properties;
        loc_tmp.address = JSON.parse(loc_tmp.address)
        loc_tmp.business_hours = JSON.parse(loc_tmp.business_hours)
        loc_tmp.location = JSON.parse(loc_tmp.location)
        loc_tmp.properties = JSON.parse(loc_tmp.properties)
        const loc = loc_tmp as IAccessPoint
        setSelectedLocation(loc);
        setshowMicroCard(true);
        setshowLocationDetailsCard(false);
    }
  }


  const clusteredPointClick = (evt: any) => {
    console.log(evt)
    if(evt.features[0].properties.cluster)
    {
        const mapRefNode = (mapRef as any).current.state.map
        mapRefNode.flyTo({
          center: evt.lngLat,
          zoom: mapValues.zoom+1,
          essential: true
        });
    }
  }

  const flyTo = (loc: IAddressResponseType) => {
    const mapRefNode = (mapRef as any).current.state.map
    mapRefNode.flyTo({
      center: loc.location.coordinates,
      essential: true
    });
  }

  const setCursor = (event: any, cursor: string) => {
    const map = event.target;
    if (map) {
      map.getCanvas().style.cursor = cursor
    }
  }


  const closeAllCards = () => {
    closeLocationDetailsCard();
    closeCard();
  }

  let locationsInBound: IAccessPoint[] = [];
  if(mapRef.current && locations.features)
  {
      const mapRefNode = (mapRef as any).current.state.map;
      const bounds = mapRefNode.getBounds();
      locations.features.forEach((location: GeoJSON.Feature) => {
        if(bounds.contains([convertToAccessPoint(location).location.coordinates.lon, convertToAccessPoint(location).location.coordinates.lat]))
        {
            locationsInBound.push(convertToAccessPoint(location));
        }
      })
  }
  
  let favorites: any = []
  if (locationsForFavorites) {
      favorites = locationsForFavorites.filter(location => favoriteIDs.includes(convertToAccessPoint(location).id)).map(location => location.properties as IAccessPoint)
  }

  return (
    <div className="map-wrap">
      <div className='sidebarStyle'>
        <div>
          Longitude: {mapValues.lon} | Latitude: {mapValues.lat} | Zoom: {mapValues.zoom}
        </div>
      </div>
      <Map className='map-container' style={"mapbox://styles/cmlunke-landolakes/ckggqtvu35ljw19my973dt7ly"} ref={mapRef} center={[mapValues.lon, mapValues.lat]} onMove={mapOnMove} onMoveEnd={mapOnMoveEnd}>
        <ZoomControl className="map-zooom-controls" />
        {/* dont touch this comment it makes it work dont ask why */}
        <GeoJSONLayer
          id='source_id'
          data={locations}
          sourceOptions={{
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50
          }}
        />
        <Layer
          id='unclustered_layer'
          interactive='true'
          sourceId='source_id'
          type='symbol'
          filter={["!", ["has", 'point_count']]}
          onClick={unclusteredPointClick}
          onMouseEnter={(e: any) => setCursor(e ,'pointer')}
          onMouseLeave={(e: any) => setCursor(e, '')}
          layerOptions={{
          }}
          layout={{
            'icon-image': 'map-pin-a',
            'icon-size': .5,
            'icon-anchor': 'bottom'
          }}/>
          <Layer
            id='clusters'
            sourceId='source_id'
            type='circle'
            filter={['has', 'point_count']}
            onClick={clusteredPointClick}
            onMouseEnter={(e: any) => setCursor(e,'pointer')}
            onMouseLeave={(e: any) => setCursor(e, '')}
          
            paint={{
              'circle-color': {
                property: 'point_count',
                type: 'interval',
                stops: [
                  [0, '#C26E47'],
                  [100, '#C26E47'],
                  [300, '#C26E47']
                ]
              },
              'circle-radius': {
                property: 'point_count',
                type: 'interval',
                stops: [
                  [0, 20],
                  [100, 30],
                  [300, 40]
                ]
              }
            }}
          />
          <Layer 
           id='cluster-count'
           type='symbol'
           sourceId='source_id'
           filter={['has', 'point_count']}
           layout= {{
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
            }}
           />
      </Map>
      <LocationDetailsCard location={selectedLocation} closeCard={closeLocationDetailsCard} showLocationDetailsCard={showLocationDetailsCard} addToFavorites={addToFavorites} favoriteIDs={favoriteIDs} removeFromFavorites={removeFromFavorites}/>
      <MapTray locations={locationsInBound} isTrayOpen={isTrayOpen} toggleTray={toggleTray} setSelectedLocation={locationClickTrigger} openLocationDetails={mapLocationClick} flyTo={flyTo} setCurrentLocation={setCurrentLocation} favorites={favorites} closeAllCards={closeAllCards}/>
      <MiniDetailsCard location={selectedLocation} showMicroCard={showMicroCard} closeCard={closeCard} openLocationDetails={microDetailsClick}/>
    </div>
  )
};

export default MapboxReactCluster;