import React from 'react';
import '../sass/main.scss';
import { IAccessPoint } from '../store/types/AccessPointTypes';
import MapTrayLocation from './MapTrayLocation';

interface MapFavoritesProps {
    show: boolean,
    close: () => void,
    favorites: IAccessPoint[],
    setLocation: (location: any) => void
  }

const MapFavorites = (prop: MapFavoritesProps) => {

  let mapTrayLocations = prop.favorites.map((location) =>
    <MapTrayLocation location={location} setLocation={() => {prop.setLocation(location)}} />
  )

if(mapTrayLocations.length == 0)
{
  mapTrayLocations.push(<div className="map-no-locations-container"><h5>No Favorites</h5><p>Sadly, you have no favorites. To add a location to your favorites, open a location card, and click/tap on the heart icon.</p></div>);
}

  return (
    <div className={prop.show ? "map-favorites-card open" : "map-favorites-card close"}>
      <span className="close acp acp-close" onClick={prop.close}></span> 
      <header>
        <h2>Favorites</h2>
      </header>               
      <section className="favorites-container">
        <div className="overflow-y-scroll">
            {mapTrayLocations}
        </div> 
      </section>            
    </div>
  )
};

export default MapFavorites;