import { Reducer } from "redux";
import { AccessPointTypes, IAccessPointState, AccessPointActions } from "../types/AccessPointTypes";

const initialAccessPointState: IAccessPointState = {
  access: [],
  loading: false
};

export const accessPointReducer: Reducer<IAccessPointState, AccessPointActions> = (
  state = initialAccessPointState,
  action
) => {
  switch (action.type) {
    case AccessPointTypes.ACCESS_POINT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case AccessPointTypes.ACCESS_POINT_FETCH_LIST: {
      return {
        ...state,
        access: action.access,
        loading: false
      };
    }
  }
  return state || initialAccessPointState;
};