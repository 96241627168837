import { CLOSED, TIME_ZERO, TIME_24OCLOCK, HOURS_24, SEPARATOR, MERIDIEM } from "../constants/constants";

const formatTime = (oldFormatTime: string) => {
    var oldFormatTimeArray = oldFormatTime.split(SEPARATOR.COLON);

    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    var AMPM = HH >= 12 && HH !== 24 ? MERIDIEM.POST : MERIDIEM.ANTE;
    var hours;
    if (HH === 0) {
        hours = HH + 12;
    } else if (HH > 12) {
        hours = HH - 12;
    } else {
        hours = HH;
    }
    var newFormatTime = hours + SEPARATOR.COLON + min + " " + AMPM;
    return newFormatTime;
}

const ValidateTime = (openTime: string, closeTime: string) => {
    if (CLOSED === openTime)
        return CLOSED
    else if (TIME_ZERO === openTime && TIME_24OCLOCK === closeTime)
        return HOURS_24
    else
        return `${formatTime(openTime)} - ${formatTime(closeTime)}`
}

export {ValidateTime};