import React, { useState, useEffect } from "react";
import { IAccessPoint } from '../store/types/AccessPointTypes'
import { getDirectionsLink } from './MiniDetailsCard'
import LocationDetailsHoursCard from "./LocationDetailsHoursCard";
import LocationDetailsNotesCard from "./LocationDetailsNotesCard";
import { ValidateTime } from "../common/utils/TimeUtils";

interface LocationDetailsCardProp {
    location: IAccessPoint,
    showLocationDetailsCard: boolean,
    closeCard: () => void,
    addToFavorites: (location: IAccessPoint) => void,
    favoriteIDs: any[],
    removeFromFavorites: (location: IAccessPoint) => void
  }

const LocationDetailsCard = (prop: LocationDetailsCardProp) => {
    const [showHours, setShowHours] = useState(false);
    const [showNotes, setShowNotes] = useState(false)
    const [addedToFavorites, setAddedToFavorites] = useState(false);

    const closeHours = () => {
        setShowHours(false);
    }

    const closeNotes = () => {
        setShowNotes(false);
    }

    useEffect(() => {
        if (!prop.showLocationDetailsCard) {
            closeHours();
            closeNotes();
        }

    }, [prop.showLocationDetailsCard])

    if(prop.location.id == null)
    {
        return (<div className={"location-details-card"}>
        </div>);
    }

    let open = "";
    let close = "";
    let timeString;
    const dayOfWeek = new Date().getDay();

    const handleFavoriteButton = () => {
        prop.addToFavorites(prop.location);
        setAddedToFavorites(!addedToFavorites);
    }

    const handleFavoriteRemove = () => {
        prop.removeFromFavorites(prop.location);
        setAddedToFavorites(!addedToFavorites);
    }

    switch(dayOfWeek) { 
        case 0: { 
            open = prop.location.business_hours.sun_open;
            close = prop.location.business_hours.sun_close;
            break; 
        } 
        case 1: { 
            open = prop.location.business_hours.mon_open;
            close = prop.location.business_hours.mon_close;
            break; 
        } 
        case 2: { 
            open = prop.location.business_hours.tue_open;
            close = prop.location.business_hours.tue_close;
            break; 
        }
        case 3: { 
            open = prop.location.business_hours.wed_open;
            close = prop.location.business_hours.wed_close;
            break; 
        } 
        case 4: { 
            open = prop.location.business_hours.thu_open;
            close = prop.location.business_hours.thu_close;
            break; 
        }
        case 5: { 
            open = prop.location.business_hours.fri_open;
            close = prop.location.business_hours.fri_close;
            break; 
        } 
        case 6: { 
            open = prop.location.business_hours.sat_open;
            close = prop.location.business_hours.sat_close;
            break; 
        } 
        default: { 
           //statements; 
           break; 
        } 
     }

     if(open && close)
     {
         timeString = ValidateTime(open,close);

     }

  return (      
    <div className={prop.showLocationDetailsCard ? "location-details-card open" : "location-details-card"}>
        <LocationDetailsHoursCard showHours={showHours} closeCard={closeHours} location={prop.location} />
        <LocationDetailsNotesCard showNotes={showNotes} closeCard={closeNotes} location={prop.location} />
        <span className="close acp acp-compress" onClick={prop.closeCard}></span>
        <header className="">
            <h1>{prop.location.display_name}</h1>
            <p className="address">
                <a href={getDirectionsLink(prop.location)}>
                    <span>{prop.location.address.freeform_address}</span>
                </a>
            </p> 
            <a href={getDirectionsLink(prop.location)} className="button button-icon on">
                Open in Maps
                <span className="icon acp acp-directions"></span>
            </a>
        </header> 
        <div className="overflow-y-scroll">
            <section className="location-card-menu-list">
                <div className="location-menu-item-row hours" onClick={() => {setShowHours(true)}}>
                    <div className="item-column icon">                    
                        <div className="icon-container unknown">
                            <i className="acp acp-wifi" ></i>
                        </div>
                    </div>
                    <div className="item-column title">
                        <h3>Wi-Fi Availability</h3>
                        <p>{timeString}</p>
                    </div>
                    <div className="item-column action">
                        <span className="acp acp-expand"></span>
                    </div>
                </div>

                <div className="location-menu-item-row notes" onClick={() => {setShowNotes(true)}}>
                    <div className="item-column icon">
                        <span className="acp acp-paperclip-o"></span>
                    </div>
                    <div className="item-column title">
                        <h3>Notes &amp; Instructions</h3>
                    </div>
                    <div className="item-column action">
                        <span className="acp acp-expand"></span>
                    </div>
                </div>

                {prop.location.website && prop.location.website.toLowerCase() !== "null" && <div className="location-menu-item-row website" onClick={()=> window.open(prop.location.website, "_blank")}>
                    <div className="item-column icon">
                        <span className="acp acp-browser"></span>
                    </div>
                    <div className="item-column title">
                        <h3>Website</h3>
                        <p><a href={prop.location.website} target="_blank" rel="noopener noreferrer">{prop.location.website}</a></p>
                    </div>
                    <div className="item-column action">
                        <span className="acp acp-angle-right"></span>
                    </div>
                </div>}

                {!prop.favoriteIDs.includes(prop.location.id) && <div className="location-menu-item-row favorite" onClick={handleFavoriteButton}>
                    <div className="item-column icon">
                        <span className="acp acp-heart-o"></span>
                    </div>
                    <div className="item-column title">
                        <h3>Add To Favorites</h3>
                        <p>Tap/click to add to favorites</p>
                    </div>
                    <div className="item-column action">
                        <span className="acp acp-plus-circle"></span>
                    </div>
                </div>}

                {prop.favoriteIDs.includes(prop.location.id) && <div className="location-menu-item-row favorite" onClick={handleFavoriteRemove}>
                    <div className="item-column icon">
                        <span className="acp acp-heart"></span>
                    </div>
                    <div className="item-column title">
                        <h3>Saved in Favorites</h3>
                        <p>Tap/click to remove from favorites</p>
                    </div>
                    <div className="item-column action">
                        <span className="acp acp-minus-circle"></span>
                    </div>
                </div>}
            </section>
        </div>              
    </div>
  );
};

export default LocationDetailsCard;