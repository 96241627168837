import React from 'react';
import '../sass/main.scss';
import {ReactComponent as ACPLogoFull} from '../assets/logos/logo-lockup-full.svg';
import {Link} from 'react-router-dom'


const Home = () => {
    return (
      <div className="home-wrap">
          <div className="home-container">            
            <ACPLogoFull className="logo full" />
            <Link className="button wifi button-icon accent" to="/map">Find Wi-Fi Locations
              <span className="icon acp acp-wifi-side"></span>
            </Link>
          </div>
      </div>
    );
};

export default Home;