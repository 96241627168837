import React from 'react';
import '../sass/main.scss';

const ContactUs = () => {
  return (
    <div className="content-container">
      <header>
        <h1>Contact Us</h1>
      </header>
      <section className="content-body">
        
        <p>Questions? Comments?</p>
        <p><a href="https://www.americanconnectionproject.com/contact-us/" target="_blank">Contact us.</a></p>
      </section>
    </div>
  )
};

export default ContactUs;