import React from 'react';
import '../sass/main.scss';



const Footer = () => {
  return (
    <footer className="footer-content-container">
      <section className="footer-left">
        <p className="powered-by">
          <a href="http://www.americanconnectionproject.com" target="_blank" rel="noopener">
            Proudly powered by
            <i className="logo acp acp-inc-logo"></i> 
          </a>
        </p>
      </section> 
      <section className="footer-right">        
        <p className="copyright">&copy;2020 Land O'Lakes, Inc. All Rights Reserved</p>
        <p className="legal"><a href="https://www.landolakesinc.com/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a></p>
      </section>    
    </footer>
  )
};

export default Footer;