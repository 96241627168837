import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom'
import '../sass/main.scss';

type Anchor = 'right';

export default function NavigationDrawer() {
  const [state, setState] = React.useState({   
    right: false
  });

  const toggleDrawer = (anchor: 'right', open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  

  return (
    <React.Fragment>
      <span className="menu acp acp-menu" onClick={toggleDrawer('right', true)}></span>      
      <Drawer anchor='right' open={state['right']} onClose={toggleDrawer('right', false)}>                    
      <List>
        <Link to="/map" onClick={toggleDrawer('right', false)}>
          <ListItem>              
            <ListItemText primary={<Typography className='navbar-link'>Find Wi-Fi</Typography>}/>
          </ListItem> 
        </Link>
        
        <Link to="/about-us" onClick={toggleDrawer('right', false)}>
          <ListItem>              
            <ListItemText primary={<Typography className='navbar-link'>About Us</Typography>}/>
          </ListItem> 
        </Link>
        
        <Link to="/contact-us" onClick={toggleDrawer('right', false)}>
          <ListItem>              
            <ListItemText primary={<Typography className='navbar-link'>Contact Us</Typography>}/>
          </ListItem> 
        </Link>
              
        </List>
      </Drawer>
    </React.Fragment>
  );
}
