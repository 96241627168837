import React from "react";
import { IAccessPoint } from "../store/types/AccessPointTypes";

interface LocationNotesCardProp {
    showNotes: boolean,
    closeCard: () => void,
    location: IAccessPoint
  }

const LocationDetailsNotesCard = (prop: LocationNotesCardProp) => {
  return (
    <div className={prop.showNotes ? "location-details-content-card location-details-card open" : "location-details-content-card location-details-card"}>
        <span className="close acp acp-compress" onClick={prop.closeCard}></span>
        <header className="">
            <h1>Notes &amp; <br/> Instructions</h1>            
        </header>

        <div className="overflow-y-scroll">
            <section className="wifi-status-widget on">          
                {prop.location.properties.network_name && <div className="wifi-details">
                    <div className="wifi-ssid">
                        <span className="label">Network Name</span>
                        <span className="description">{prop.location.properties.network_name}</span>
                    </div>
                </div>}
            </section>

            <section className="location-notes-container">                
                <b>Instructions:</b>
                {prop.location.properties.instructions}        
            </section>
        </div>         
    </div>
  );
};

export default LocationDetailsNotesCard;