import React from 'react';
import '../sass/main.scss';

const AboutUs = () => {
  return (
    <div className="content-container">
      <header>
        <div className="hero about-us"></div>                
        <h1>What is the American Connection Project? </h1>
      </header>
      <section className="content-body">
        
        <p>Millions of Americans go without broadband internet access—most of them in rural communities. The American Connection Project fosters long-term digital inclusion in support of vibrant and thriving rural communities. Efforts include raising awareness about the critical nature of connectivity; advocating for meaningful policy reform and broadband investment; and, taking decisive action to provide connectivity options where they’re needed most. Land O’Lakes is convening organizations and leaders from across the country in this critical time to build strong rural communities positioned for competitiveness and connection now and in the future. </p>
        <p>Learn more at <a href="http://www.americanconnectionproject.com" target="_blank" rel="noopener">www.americanconnectionproject.com</a></p>
      </section>
    </div>    
  )
};

export default AboutUs;