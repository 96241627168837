import React, { useState } from 'react';
import '../sass/main.scss';
import { IAccessPoint } from '../store/types/AccessPointTypes';
import MapTrayLocation from './MapTrayLocation';
import MapSearch from './MapSearch';
import MapFavorites from './MapFavorites';
import { IAddressResponseType } from '../store/types/AddressResponseTypes';

interface MapTrayProps {
    locations: IAccessPoint[],    
    isTrayOpen: boolean,
    toggleTray: () => void,
    setSelectedLocation: (location: any) => void,
    openLocationDetails: (location: any) => void,
    flyTo: (location: IAddressResponseType) => void,
    setCurrentLocation: () => void,
    closeAllCards: () => void,
    favorites: IAccessPoint[]
  }

const MapTray = (prop: MapTrayProps) => {
    const [showSearch, setShowSearch] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);
    const [contentClass, setContentClass] = useState("footer-wrap");

    const closeSearch = () => {
        setShowSearch(false);
        setContentClass("footer-wrap");
    }

    const closeFavorites = () => {
        setShowFavorites(false);
        setContentClass("footer-wrap");
    }

    const openSearch = () => {
        closeFavorites();
        prop.closeAllCards()
        setShowSearch(true);
        setContentClass("footer-wrap search-open");
    }

    const openFavorites = () => {
        closeSearch();
        prop.closeAllCards()
        setShowFavorites(true);
        setContentClass("footer-wrap favorites-open");
    }

    let mapTrayLocations = prop.locations.map((location) =>
        <MapTrayLocation location={location} setLocation={() => {prop.setSelectedLocation(location)}} />
    )

    if(mapTrayLocations.length === 0)
    {
        mapTrayLocations.push(<div className="map-no-locations-container"><h5>No Locations Found</h5><p>Try zooming the map out or searching for another address, ZIP code, or city.</p></div>);
    }

  return (
    <div className={contentClass}>   
    {/* TODO
      The current div above (footer-wrap) needs to toggle the class 'favorites-open' when a user clicks on favorites in the footer nav.
      The current functionality of toggling the search-open class will also need to remain. 
      If the class 'search-open' is on 'footer-wrap' and a user clicks/taps on favorites, 'search-open' will need to be removed, and 'favorites-open' will need to be added. 
      If the class 'favorites-open' is on 'footer-wrap' and a user clicks/taps on search, 'favorites-open' will need to be removed, and 'search-open' will need to be added. 
      On the MapFavoites component, the class 'open' will need to be toggled on the div with class 'map-favorites-card'
    */}     
      <div className="footer-nav">
        <nav>
          <a className="nav-item search" href="#" onClick={openSearch}>
            <span className="nav-item-icon acp acp-wifi"></span>  
            <span className="nav-item-title">Search</span>  
          </a>  
          <a className="nav-item favorites" href="#" onClick={openFavorites}>
            <span className="nav-item-icon acp acp-heart"></span>  
            <span className="nav-item-title">Favorites</span>  
          </a>
        </nav>                    
      </div>
      <MapSearch show={showSearch} close={closeSearch} flyTo={prop.flyTo} setCurrentLocation={prop.setCurrentLocation} />
      <MapFavorites show={showFavorites} close={closeFavorites} favorites={prop.favorites} setLocation={prop.setSelectedLocation} />      
      <div className={"footer-tray " + (prop.isTrayOpen ? 'open' : '')} >
        <div className="tray-trigger" onClick={prop.toggleTray}><span className="grab-lines acp acp-grab-lines"></span></div>
        <div className="tray-overflow">
          {mapTrayLocations}
        </div>        
      </div>    
    </div>
  )
};

export default MapTray;