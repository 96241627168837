import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
//import { offline } from '@redux-offline/redux-offline';
//import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import createEncryptor from 'redux-persist-transform-encrypt';
import * as localForage from 'localforage';
import { accessPointReducer } from './reducers/accessPointReducer'

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

const encryptor = createEncryptor({
	secretKey: 'fixthislaster',
});

const persistOptions = {
	key: 'pp',
	storage: localForage,
	blacklist: ['rehydrate'],
	transforms: [encryptor],
};

/*const config = {
	...offlineConfig,
	persistOptions,
};*/
const rootReducer = combineReducers({
	access: accessPointReducer,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk)/* , offline(config) */)
);

export { store };
